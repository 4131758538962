import React from "react";
import './style.scss';


export default (props) => {

  return (
    <div className="wrapper">
      <div className="item">
        <div className="title">1、预订</div>
        <div className="pdb-30">
          <span className="sub-title">1.1 在线预订：</span>
          <span className="span">登录八大洲旅游-实时预订，按系统提示完成您的预订；为确保您能够顺利入住，请在填写订单时，务必留下准确的入住人姓名（请确保与所使用的有效证件上的姓名一致）。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.2 最晚预订时间：</span>
          <span className="span">建议您最晚入住当天下午18点前预订，避免因为酒店预订部门非工作时间不能及时处理您的预订，如果超过18点预订，抵达酒店查询不到预订，请及时联系我们协助。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.3 最晚修改及取消时间：</span>
          <span className="span">订单有规定的最晚修改及取消时间，超过此时间将可能给您造成损失。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.4 </span>
          <span className="span">当您需要预订多间客房时，可以先联系我们确认房态，由我们确认后再行预订，自助预订的时候，每间房间的入住人姓名需要不同， 以方便预订确认。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.5 </span>
          <span className="span">如您已为相同入住人预订了入离时段相同（或部分重合）的客房，请不要重复预订。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.6 </span>
          <span className="span">在您提交订单，提示预订成功后，表示您的预订已经完成，您可如期安排您的行程。如果出现其他提示，请按照提示操作，或者联系客服。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.7 </span>
          <span className="span">到店付“确认后享”的促销类价格，需联系在线客服经我们二次确认后再行预订。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.8 </span>
          <span className="span">预订多间房间时候，到店付消费抵扣额度，不可合并使用。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.9 </span>
          <span className="span">连续入住拆分为多次预订，或相同入住人使用两个名字交替预订，到店付仅可享受一次专享礼遇。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.10 </span>
          <span className="span">关于专享价礼遇的中的视房态升级房型，一般情况下升级仅限：普通房升级普通房，行政房升级行政房，套房升级套房，无特别促销情况下不可跨等级升级；酒店有权根据当天入住时的房态决定升级与否，具体以到店时候酒店安排为准。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">1.11 </span>
          <span className="span">关于专享价礼遇中的视房态提前入住或延迟退房，需要在入住之前或离店之前与酒店确认具体时间，具体以酒店安排为准。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">2、费用说明</div>

        <div className="pdb-30">
          <span className="sub-title">2.1 </span>
          <span className="span">小程序预订成功后呈现的酒店价格包含实际需要支付的当地货币价格，但不包含地区城市税，度假村税等。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">2.2 </span>
          <span className="span">小程序呈现价格，如有不同的促销方案，预订成功后价格因为促销方案原因会有所变更，请预订之前联系在线客服以最终确认为准。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">2.3 </span>
          <span className="span">您入住酒店的早餐与加床价格视各酒店规定有所不同。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">3、入住、延住与离店</div>

        <div className="pdb-30">
          <span className="sub-title">3.1 </span>
          <span className="span">通常情况下，酒店的最早入住时间为14：00，最晚离店时间为中午12：00，若您提前入住，请与酒店协商，可能会遇到无空房的情况，需要您等待。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">3.2 </span>
          <span className="span">如遇到涉及凌晨后的较晚入住，请提前和酒店联系说明，如未能如期到达请及时与酒店联系，否则酒店可能将会取消您的订单。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">3.3 </span>
          <span className="span">若您因行程变化取消了酒店订单，但之后再次需要预订时，价格将有可能会变更，请以最新确认的价格为准。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">4、您的权利和责任</div>
        <div className="pdb-30">
          <span className="sub-title">4.1 </span>
          <span className="span">若订单已确认，您可如期入住所预订的酒店。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">4.2 </span>
          <span className="span">若订单已确认，您在约定时间至预订酒店前台发现无房或者无预订等特殊情况，请立即致电我们，以便我们尽快帮您协调解决。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">4.3 </span>
          <span className="span">您对您的预订亦负有相应的责任，如您在酒店入住时遇到困难，请致电我们（4000882020），我们将为您提供必要的帮助。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">5、关于到店付信用卡担保</div>
        <div className="pdb-30">
          <span className="sub-title">5.1 </span>
          <span className="span">八大洲小程序预订到店付价格都需要您提供信用卡担保。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">5.2 </span>
          <span className="span">信用卡担保所支持的卡种以系统提示选项为准，并请您提供正确的信用卡信息，如信用卡信息错误，酒店和我们将有权取消您的预订。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">5.3 </span>
          <span className="span">需要信用卡担保的酒店，订单一经预订成功，将严格遵循相应的退改规定要求。因此在填写担保订单前请再次确认您的预订及退改规定，以免给您造成不必要的损失。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">5.4 </span>
          <span className="span">如您未按订单时间或房间数量入住， 酒店将按国际惯例扣除相应的房费。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">6、 免责条款</div>
        <div className="pdb-30">
          <span className="sub-title">6.1 </span>
          <span className="span">八大洲旅游酒店预订系统仅提供酒店预订服务，当您在预订酒店时候确保已经充分了解目的地的季节特性、天气状况、政治，军事局势、风险程度等，并采取必要的预防措施。同时建议您自行购买旅行意外保险，八大洲旅游对您入住酒店期间的意外（人身、财产）损失，不承担责任。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">6.2 </span>
          <span className="span">因服务器错误、误导或重新导入传输、网络连接失败、传输或接受预订中断或计算机病毒等技术缺陷（人为的或技术的）而造成您实际财产损失的（提供有效证明），本公司承担责任范围内可以合理预见的实际经济损失为限。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">6.3 </span>
          <span className="span">若因酒店原因或者技术原因所产生的价格、房型等信息明显错误，酒店将有权视此类订单为无效订单。如您发现明显的价格异常，请联系在线客服确认后再预订，若在未经过本公司客服确认的情况下所产生的此类预订，将被视为无效预订。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">6.4 </span>
          <span className="span">到店付“专享礼遇”因各酒店实际的运营情况可能会在合理的范围内临时调整，所以“专享礼遇”具体内容以入住时实际酒店提供为准。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">6.5 </span>
          <span className="span">酒店提供的专享礼遇内容，仅限在前台登记的入住人与同住人使用，且整个入住期间只能享受一次。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">6.6 </span>
          <span className="span">若遇特殊日期及节假日，应酒店要求本公司有权要求客户对相应订单提前付款至本公司，以保证订单顺利入住。</span>
        </div>
      </div>
      <div className="item pdb-30 senven">
        <div className="title">7、使用条款</div>
        <div className="pdb-30 bold">
          出现以下情形之一，您的会员账户将被关闭并且积分清零：
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">转售我司的酒店产品及专享礼遇；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">超过退改期限的到店付预订，未经许可违规取消；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">预订到店付订单，未实际到店入住，且不承担房费损失的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">将本人注册会员帐号转让给第三方使用；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">连续入住，用所有入住人名字交替预订；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">使用虚假姓名预订并自行联系酒店修改入住人姓名；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">·</span>
          <span className="span">使用虚假或无效的信用卡信息进行担保预订的行为。</span>
        </div>
      </div>
    </div>
  );
}