import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { Calendar } from '../../../components';
import { getCheckDate, getBrandList, changeSelBrand, changeSelMember } from '../../../store/home/action';
import { resetHotelListData } from '../../../store/hotelList/action';
import { setCheckDateStorage } from '../../../utils/hotel';
import { URL } from '../../../utils/constants';
import { wechatGenerateScheme } from "../../../api";
import 'swiper/swiper.scss';
import './style.scss';



function Hotel(props) {
  // 如果是来自小程序嵌套，就不能在显示前往小程序了
  const from = props.from;
  // let brands;
  const dispatch = useDispatch();
  let history = useHistory();
  const schemeRef = useRef();
  // 初始化获取首页数据
  const { city, checkDate, selBrand, selMember, merchantNo, banner } = useSelector(state => state.home);
  const { hotels } = useSelector(state => state.hotelList);
  const path = "/pages/index/index";
  const randomNum = parseInt(Math.random() * 100000000);
  const query = merchantNo ? `merchantNo=${merchantNo}&randomNum=${randomNum}` : `randomNum=${randomNum}`;


  const calendarRef = useRef();
  // 获取选择的日期
  function getChangeDate(date) {
    calendarRef.current.hide();
    dispatch(getCheckDate(date));
    setCheckDateStorage(date);
  }

  //日历组件状态
  const [calendarState, setCalendarState] = useState(false);
  // 合作品牌
  let brandData = useSelector(state => state.home.brands);
  if (brandData) {
    // brands = JSON.parse(brandData.data);
  }
  useEffect(() => {
    // 如果store 没有热门城市数据 就请求数据
    if (!brandData) {
      dispatch(getBrandList());
    }
    // 在首页如果酒店列表页有数据 需要重新清空，以便再次进入重新获取数据
    if (hotels) {
      dispatch(resetHotelListData());
    }
    // 外部浏览器打开的请求跳转吗
    wechatGenerateScheme({ path, query }).then((res) => {
      schemeRef.current = res.data;
    }).catch((err) => { });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 查询酒店列表
  function search() {
    history.push('/hotel/list');
  }

  function goToMiniApp() {
    window.location.href = schemeRef.current.scheme;
  }


  return (
    <div className="hotel-wrapper">
      {!from && (
        <div onClick={goToMiniApp} className="xcx">
          <img className='img' src="https://staticfile.badazhou.com/20221102/0b01e82b2975529167e68d49fde64198.jpeg" alt="八大洲小程序" />
          <span className='btn'>立即打开</span>
        </div>
      )}


      <div className="banner">
        {banner.link ? (
          <Link to={banner.link}>
            <img src={banner.imgUrl} alt='' />
          </Link>
        ) : (
          <img src={banner.imgUrl} alt='' />
        )}
      </div>
      <div className="search-box">
        <Link to={URL.hotelAreaList} className="search-item">
          <div className="bold">{city.nameCn}</div>
          <span className='arrow iconfont icon-xiangyoujiantou'></span>
        </Link>
        <div className='search-item' onClick={() => {
          setCalendarState(true);
          calendarRef.current.show();
        }}>
          <div className='date-item'>
            <div>
              <span className="dateNum">{checkDate.checkinTxt}</span>
              <span className="dateDay">{checkDate.checkinDay}</span>
            </div>
            <div className='days-box'>
              <span className='line'></span>
              <span className='days'>住{checkDate.days}晚</span>
            </div>
            <div>
              <span className="dateNum">{checkDate.checkoutTxt}</span>
              <span className="dateDay">{checkDate.checkoutDay}</span>
            </div>
          </div>
          <div>
            <span className='arrow iconfont icon-xiangyoujiantou'></span>
          </div>
        </div>
        <Link to={`/hotel/search/${city.id}`} className="search-item">
          {(selBrand || selMember) ? (
            <>
              {selBrand && <span>{selBrand.nameCn}</span>}
              {selMember && <span>{selMember.name}</span>}
            </>
          ) : (
            <span className="desc-v3-color">搜索酒店或查看推荐</span>
          )}

          {(selBrand || selMember) ? (
            <CloseCircleOutlined
              style={{ color: '#b2b2b2' }}
              onClick={(event) => {
                if (selMember) {
                  dispatch(changeSelMember(null));
                }
                if (selBrand) {
                  dispatch(changeSelBrand(null));
                }
                event.preventDefault();
              }}
            />
          ) : (
            <span className='arrow iconfont icon-xiangyoujiantou'></span>
          )}
        </Link>

        <div className="white-space"></div>

        <Button type="primary" block size="large" onClick={search}>
          查询
        </Button>

      </div>



      {/* {brands && (
        <div className="brands">
          <div className="title">30+酒店品牌享专属优待</div>
          <div className="desc">免费早餐 · 视房态升级 · 抵用额度 · 欢迎礼遇</div>
          <Swiper
            className="swiper"
          >
            {brands.map((brand, index) => 
              <SwiperSlide className="swiper-slide" key={index}>
                <img className="img" alt="" src={brand} />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      )} */}
      {/* 来自京东用户 */}
      {merchantNo === 'cdFT2HRXBBT4' && (
        <a href="https://plus.m.jd.com/liferight/index">
          <img className="jd-link" src='https://staticfile.badazhou.com/20210615/bb87c1c72b72e692825d50ac1a12a7d1.jpeg-750xauto' alt='' />
        </a>
      )}

      <Suspense fallback={<></>}>
        <Calendar calendarState={calendarState} ref={calendarRef} checkDate={checkDate} onChange={getChangeDate} />
      </Suspense>
    </div>
  );
}

export default React.memo(Hotel);
